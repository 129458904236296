import React from 'react'
import Swiper from 'react-id-swiper';
// Need to add Pagination, Navigation modules
import { Navigation } from 'swiper/dist/js/swiper.esm'
import Img from "gatsby-image";
import {Link} from "gatsby";


const Slider = ({slides}) => {

    const params = {
        modules: [Navigation],
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        spaceBetween: 0,
        containerClass: 'home__slider',
        slidesPerView: 1
    }

    /**
     * Render the link depending on the target value
     * @param link
     * @returns {*}
     */
    const renderLink = (link) => {
        if(link.target === null) {
            return <Link to={link.url} className="btn btn-light follow-link" dangerouslySetInnerHTML={{__html: link.title}} />
        }

        return <a href={link.url} target="_blank" rel="noopener noreferrer" className="btn btn-light follow-link" dangerouslySetInnerHTML={{__html: link.title}} />
    }

    return(
        <Swiper {...params}>
            {slides.map((item, idx) => {
                return (
                    <div className="slide" key={idx}>
                        { (item.image != null)? <Img fluid={item.image.localFile.childImageSharp.fluid} style={{ position: "absolute", top: 0, left: 0, width: `100%`, height: `100%`}}/> : '<img src="" />' }
                        <div className="slide-text">
                            <div className="text-label" dangerouslySetInnerHTML={{ __html: item.label}} />
                            <div className="text" dangerouslySetInnerHTML={{__html: item.text}} />
                            <div>
                                { renderLink(item.link) }
                            </div>
                        </div>
                    </div>
                )
            })}
        </Swiper>
    )
}

export default Slider

