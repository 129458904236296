import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SquareLink from "../components/square_link"
import PageHeader from "../components/page_header"

export const query = graphql`
  query($id: String!, $wordpress_id: Int!) {
    category: wordpressPage(id: { eq: $id }) {
      id
      slug
      title
      acf {
        description
        banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 4160) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_description
        yoast_wpseo_facebook_type
        yoast_wpseo_facebook_image
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
        yoast_wpseo_twitter_image
        yoast_wpseo_social_url
        yoast_wpseo_company_or_person
        yoast_wpseo_person_name
        yoast_wpseo_company_name
        yoast_wpseo_company_logo
        yoast_wpseo_website_name
      }
    }
    children: allWordpressPage(
      filter: { wordpress_parent: { eq: $wordpress_id } }
    ) {
      edges {
        node {
          id
          slug
          title
          path
          acf {
            thumbnail_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 385) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const Category = ({ data }) => {
  const category = data.category
  const children = data.children.edges
  const meta = data.category.yoast_meta

  return (
    <Layout>
      <SEO
        title={meta.yoast_wpseo_title}
        description={meta.yoast_wpseo_metadesc}
      />
      <PageHeader title={category.title} image={category.acf.banner_image} />
      <main className="content__container -category">
        <section
          className="category__description"
          dangerouslySetInnerHTML={{ __html: category.acf.description }}
        />
        <div className="category__subcategories">
          {children.map(item => (
            <div key={item.node.id} className="category__subcategories-item">
              <SquareLink
                title={item.node.title}
                link={`/${item.node.path}`}
                image={item.node.acf.thumbnail_image}
              />
            </div>
          ))}
        </div>
      </main>
    </Layout>
  )
}

export default Category
