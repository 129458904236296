import React from "react"

import Layout from "../components/layout"
import Slider from "../components/slider"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import SquareLink from "../components/square_link"

export const query = graphql`
  query($id: String!) {
    home: wordpressPage(id: { eq: $id }) {
      id
      slug
      title
      acf {
        slides {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          label
          text
          link {
            url
            target
            title
          }
        }
        nos_produits {
          post_title
          post_name
          acf {
            thumbnail_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_description
        yoast_wpseo_facebook_type
        yoast_wpseo_facebook_image
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
        yoast_wpseo_twitter_image
        yoast_wpseo_social_url
        yoast_wpseo_company_or_person
        yoast_wpseo_person_name
        yoast_wpseo_company_name
        yoast_wpseo_company_logo
        yoast_wpseo_website_name
      }
    }
  }
`

const Homepage = ({ data, ...rest }) => {
  const products = data.home.acf.nos_produits
  const slides = data.home.acf.slides
  const meta = data.home.yoast_meta

  console.log(rest)

  const renderOurProductsSection = products => {
    return products.map(item => {
      return (
        <div key={item.post_name} className="home__material-item">
          <SquareLink
            title={item.post_title}
            link={`/${item.post_name}`}
            image={item.acf.thumbnail_image}
          />
        </div>
      )
    })
  }

  return (
    <Layout>
      <SEO
        title={meta.yoast_wpseo_title}
        description={meta.yoast_wpseo_metadesc}
        keywords={[]}
      />
      <Slider slides={slides} />
      <main className="content__container">
        <section className="home__material">
          <div className="home__material-title">
            <h2>Nos Produits</h2>
          </div>
          {renderOurProductsSection(products)}
        </section>
        <section className="home__about">
          <h1>Matériaux de construction</h1>
          <div className="left">
            <p>
              Négoce familliale de plus de cent ans, l'entreprise Paul Roth et
              Fils est un acteur majeur du bâitiment en Alsace. Elle vend
              l'ensemble des produits sanitaire, chauffage, plomberie, toiture,
              bois, étanchenité, zinguerie, isolation et outillage. Des
              livraisons par camion grues sont assurées sur l'ensemble de
              l'Alsace. Basé à Obernai et Illzach, Paul Roth et Fils est associé
              aux enseignes France Materiaux et Au Fil du Bain.
            </p>
          </div>
          <div className="right" />
        </section>
      </main>
    </Layout>
  )
}

export default Homepage
