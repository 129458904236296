import React from "react"
import BackgroundImage from "gatsby-background-image"
//import Breadcrumbs from "../components/breadcrumbs"

const PageHeader = ({ image, title }) => {
  if (image === null) {
    return <div />
  }

  const imageData = image.localFile.childImageSharp.fluid
  return (
    <BackgroundImage
      Tag="header"
      className="page__header"
      fluid={imageData}
      backgroundColor={`#040e18`}
    >
      <div className="content__container">
        <h1 dangerouslySetInnerHTML={{ __html: title }} />
      </div>
    </BackgroundImage>
  )
}

export default PageHeader
